.root {
  display: flex;
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  flex-shrink: 0;
  min-width: 0;
  padding: var(--token-omGhEM8OI) var(--token-TCakqYrfy);
}
.rootoverflowImage {
  padding: 0px;
}
@media (min-width: 600px) {
  .rootoverflowImage {
    padding: 0px;
  }
}
.headerContentContainer {
  flex-direction: row;
  display: flex;
  position: relative;
  width: 100%;
  min-width: 0;
  --plsmc-rc-col-gap: 0px;
}
.headerContentContainer > :global(.__wab_flex-container) {
  flex-direction: row;
  flex-wrap: wrap;
  align-items: stretch;
  min-width: 0;
  margin-left: calc(0px - var(--token-cTiaoKBgu));
  width: calc(100% + var(--token-cTiaoKBgu));
  --plsmc-rc-col-gap: var(--token-cTiaoKBgu);
  margin-top: calc(0px - var(--token-cTiaoKBgu));
  height: calc(100% + var(--token-cTiaoKBgu));
}
.headerContentContainer > :global(.__wab_flex-container) > *,
.headerContentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.headerContentContainer > :global(.__wab_flex-container) > picture > img,
.headerContentContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: var(--token-cTiaoKBgu);
  margin-top: var(--token-cTiaoKBgu);
}
.headerContentContainer > :global(.__wab_flex-container) > :nth-child(1n + 1) {
  width: calc((100% - 1 * var(--plsmc-rc-col-gap, 0px)) * 12 / 12);
}
@media (min-width: 600px) {
  .headerContentContainer > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    --plsmc-rc-col-gap: 0px;
    margin-top: calc(0px - var(--token-omGhEM8OI));
    height: calc(100% + var(--token-omGhEM8OI));
  }
}
@media (min-width: 600px) {
  .headerContentContainer > :global(.__wab_flex-container) > *,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .headerContentContainer > :global(.__wab_flex-container) > picture > img,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-omGhEM8OI);
  }
}
@media (min-width: 900px) {
  .headerContentContainer {
    flex-direction: row;
  }
}
@media (min-width: 900px) {
  .headerContentContainer > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: calc(0px - var(--token-TCakqYrfy));
    width: calc(100% + var(--token-TCakqYrfy));
    --plsmc-rc-col-gap: var(--token-TCakqYrfy);
  }
}
@media (min-width: 900px) {
  .headerContentContainer > :global(.__wab_flex-container) > *,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .headerContentContainer > :global(.__wab_flex-container) > picture > img,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-TCakqYrfy);
  }
}
@media (min-width: 900px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1024px) {
  .headerContentContainer {
    flex-direction: row;
  }
}
@media (min-width: 1024px) {
  .headerContentContainer > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
    margin-left: calc(0px - var(--token-cTiaoKBgu));
    width: calc(100% + var(--token-cTiaoKBgu));
    --plsmc-rc-col-gap: var(--token-cTiaoKBgu);
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 1024px) {
  .headerContentContainer > :global(.__wab_flex-container) > *,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .headerContentContainer > :global(.__wab_flex-container) > picture > img,
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: var(--token-cTiaoKBgu);
    margin-top: 0px;
  }
}
@media (min-width: 1024px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1024px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1280px) {
  .headerContentContainer {
    flex-direction: row;
  }
}
@media (min-width: 1280px) {
  .headerContentContainer > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 1280px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1280px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1536px) {
  .headerContentContainer {
    max-width: var(--token-c-29j4nMe);
  }
}
@media (min-width: 1536px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1536px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1900px) {
  .headerContentContainer {
    width: 80%;
  }
}
@media (min-width: 1900px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1900px) {
  .headerContentContainer
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.headerContentContaineroverflowImage {
  padding-bottom: 0px;
}
@media (min-width: 900px) {
  .headerContentContaineroverflowImage {
    padding-top: 0px;
    padding-right: 0px;
  }
}
@media (min-width: 900px) {
  .headerContentContainerlearnHeading {
    flex-direction: row;
  }
}
@media (min-width: 900px) {
  .headerContentContainerlearnHeading > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: nowrap;
    margin-top: calc(0px - 0px);
    height: calc(100% + 0px);
  }
}
@media (min-width: 900px) {
  .headerContentContainerlearnHeading > :global(.__wab_flex-container) > *,
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > picture
    > img,
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-top: 0px;
  }
}
@media (min-width: 900px) {
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 900px) {
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1900px) {
  .headerContentContainerlearnHeading {
    flex-direction: row;
    width: 80%;
    max-width: var(--token-c-29j4nMe);
  }
}
@media (min-width: 1900px) {
  .headerContentContainerlearnHeading > :global(.__wab_flex-container) {
    flex-direction: row;
    flex-wrap: wrap;
  }
}
@media (min-width: 1900px) {
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :nth-child(2n + 1) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
@media (min-width: 1900px) {
  .headerContentContainerlearnHeading
    > :global(.__wab_flex-container)
    > :nth-child(2n + 2) {
    width: calc((100% - 2 * var(--plsmc-rc-col-gap, 0px)) * 6 / 12);
  }
}
.copyContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  min-width: 0;
  padding: 0px;
}
.copyContainer > :global(.__wab_flex-container) {
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 0;
  margin-top: calc(0px - var(--token-TCakqYrfy));
  height: calc(100% + var(--token-TCakqYrfy));
}
.copyContainer > :global(.__wab_flex-container) > *,
.copyContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
.copyContainer > :global(.__wab_flex-container) > picture > img,
.copyContainer
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-top: var(--token-TCakqYrfy);
}
@media (min-width: 900px) {
  .copyContainer > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1024px) {
  .copyContainer {
    padding-left: var(--token-cTiaoKBgu);
    padding-right: var(--token-TCakqYrfy);
  }
}
@media (min-width: 1024px) {
  .copyContainer > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-TCakqYrfy));
    height: calc(100% + var(--token-TCakqYrfy));
  }
}
@media (min-width: 1024px) {
  .copyContainer > :global(.__wab_flex-container) > *,
  .copyContainer > :global(.__wab_flex-container) > :global(.__wab_slot) > *,
  .copyContainer > :global(.__wab_flex-container) > picture > img,
  .copyContainer
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-TCakqYrfy);
  }
}
@media (min-width: 1280px) {
  .copyContainer {
    padding-right: 0px;
  }
}
.copyContaineroverflowImage {
  padding-top: var(--token-omGhEM8OI);
  padding-left: var(--token-TCakqYrfy);
  padding-right: var(--token-TCakqYrfy);
}
@media (min-width: 900px) {
  .copyContaineroverflowImage {
    padding-top: var(--token-omGhEM8OI);
    padding-bottom: var(--token-omGhEM8OI);
    padding-right: 0px;
  }
}
@media (min-width: 1024px) {
  .copyContaineroverflowImage {
    padding-left: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1536px) {
  .copyContaineroverflowImage > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
.copyContainerlearnHeading > :global(.__wab_flex-container) {
  align-items: flex-start;
  justify-content: flex-start;
}
@media (min-width: 600px) {
  .copyContainerlearnHeading {
    padding-bottom: 0px;
  }
}
@media (min-width: 900px) {
  .copyContainerlearnHeading {
    padding-bottom: var(--token-omGhEM8OI);
    padding-right: 0px;
  }
}
@media (min-width: 1024px) {
  .copyContainerlearnHeading {
    padding-left: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1024px) {
  .copyContainerlearnHeading > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1280px) {
  .copyContainerlearnHeading {
    padding-right: 0px;
    padding-left: var(--token-cTiaoKBgu);
  }
}
@media (min-width: 1280px) {
  .copyContainerlearnHeading > :global(.__wab_flex-container) {
    justify-content: center;
    align-items: flex-start;
  }
}
@media (min-width: 1900px) {
  .copyContainerlearnHeading {
    padding-right: 0px;
    padding-left: var(--token-JIfrimaWtNy);
  }
}
@media (min-width: 1900px) {
  .copyContainerlearnHeading > :global(.__wab_flex-container) {
    margin-left: calc(0px - 0px);
    width: calc(100% + 0px);
    margin-top: calc(0px - var(--token-TCakqYrfy));
    height: calc(100% + var(--token-TCakqYrfy));
  }
}
@media (min-width: 1900px) {
  .copyContainerlearnHeading > :global(.__wab_flex-container) > *,
  .copyContainerlearnHeading
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > *,
  .copyContainerlearnHeading > :global(.__wab_flex-container) > picture > img,
  .copyContainerlearnHeading
    > :global(.__wab_flex-container)
    > :global(.__wab_slot)
    > picture
    > img {
    margin-left: 0px;
    margin-top: var(--token-TCakqYrfy);
  }
}
.freeBox__ngGtH {
  position: relative;
  flex-direction: column;
  display: none;
}
.freeBox__ngGtH > :global(.__wab_flex-container) {
  flex-direction: column;
}
.freeBoxlearnHeading__ngGtHGl9Fo {
  align-self: flex-start;
  display: flex;
}
.freeBoxlearnHeading__ngGtHGl9Fo > :global(.__wab_flex-container) {
  margin-left: calc(0px - 0px);
  width: calc(100% + 0px);
  margin-top: calc(0px - var(--token-rYh-ACW-A));
  height: calc(100% + var(--token-rYh-ACW-A));
}
.freeBoxlearnHeading__ngGtHGl9Fo > :global(.__wab_flex-container) > *,
.freeBoxlearnHeading__ngGtHGl9Fo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > *,
.freeBoxlearnHeading__ngGtHGl9Fo
  > :global(.__wab_flex-container)
  > picture
  > img,
.freeBoxlearnHeading__ngGtHGl9Fo
  > :global(.__wab_flex-container)
  > :global(.__wab_slot)
  > picture
  > img {
  margin-left: 0px;
  margin-top: var(--token-rYh-ACW-A);
}
.svg__a1Fye {
  width: 110px;
  height: 26px;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-8j5e4W9rawI);
  flex-shrink: 0;
}
.svg__tCsN {
  width: 216px;
  height: 44px;
  align-self: auto;
  position: relative;
  object-fit: cover;
  max-width: 100%;
  color: var(--token-TDUv9IlsPLe);
  flex-shrink: 0;
}
.h1 {
  justify-content: center;
  display: flex;
  flex-direction: column;
}
@media (min-width: 1024px) {
  .h1 {
    justify-content: flex-start;
  }
}
.h1learnHeading {
  text-align: left;
}
@media (min-width: 1900px) {
  .h1learnHeading {
    max-width: 1024px;
    text-align: center;
  }
}
.slotTargetHeading {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-PsTTGOOvU);
  font-weight: 700;
  font-size: var(--token-hOqCuytDszPG);
  line-height: var(--token-wmteqkiSA);
  letter-spacing: normal;
  text-align: center;
}
.slotTargetHeading > :global(.__wab_text),
.slotTargetHeading > :global(.__wab_expr_html_text),
.slotTargetHeading > :global(.__wab_slot-string-wrapper),
.slotTargetHeading > :global(.__wab_slot) > :global(.__wab_text),
.slotTargetHeading > :global(.__wab_slot) > :global(.__wab_expr_html_text),
.slotTargetHeading > :global(.__wab_slot) > :global(.__wab_slot-string-wrapper),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_text),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_expr_html_text),
.slotTargetHeading
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot)
  > :global(.__wab_slot-string-wrapper) {
  text-decoration-line: none;
}
@media (min-width: 900px) {
  .slotTargetHeading {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .slotTargetHeading {
    text-align: left;
  }
}
.slotTargetHeadinglearnHeading {
  text-align: left;
}
@media (min-width: 1024px) {
  .slotTargetHeadinglearnHeading {
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .slotTargetHeadinglearnHeading {
    text-align: left;
  }
}
.p {
  color: var(--token-KSYikVdQL);
  font-family: var(--token-Hn0tANBmC);
  font-weight: 400;
  font-size: var(--token-DckCCvtwain);
  line-height: var(--token-MHxx4_Rh4);
  position: relative;
  width: 100%;
  height: auto;
  justify-content: center;
  display: flex;
  flex-direction: column;
  min-width: 0;
}
@media (min-width: 600px) {
  .p {
    display: flex;
  }
}
@media (min-width: 1024px) {
  .p {
    text-align: left;
    justify-content: flex-start;
  }
}
@media (min-width: 1280px) {
  .p {
    padding-bottom: 0px;
  }
}
@media (min-width: 1536px) {
  .p {
    align-items: flex-start;
    justify-content: flex-start;
  }
}
@media (min-width: 1900px) {
  .plearnHeading {
    max-width: 1024px;
    text-align: center;
  }
}
.slotTargetText {
  text-align: center;
  font-size: var(--token-XMoXRN_s63-);
}
@media (min-width: 900px) {
  .slotTargetText {
    text-align: left;
  }
}
@media (min-width: 1024px) {
  .slotTargetText {
    text-align: left;
  }
}
.slotTargetTextlearnHeading {
  text-align: left;
}
@media (min-width: 1024px) {
  .slotTargetTextlearnHeading {
    text-align: left;
  }
}
@media (min-width: 1280px) {
  .slotTargetTextlearnHeading {
    text-align: left;
  }
}
.freeBox__fXzGo {
  display: flex;
  position: relative;
  flex-direction: column;
  margin-top: var(--token-JIfrimaWtNy) !important;
  align-items: center;
  justify-content: center;
  width: auto;
}
@media (min-width: 600px) {
  .freeBox__fXzGo {
    margin-top: var(--token-JIfrimaWtNy) !important;
  }
}
@media (min-width: 900px) {
  .freeBox__fXzGo {
    margin-top: var(--token-JIfrimaWtNy) !important;
  }
}
@media (min-width: 1024px) {
  .freeBox__fXzGo {
    margin-top: var(--token-JIfrimaWtNy) !important;
  }
}
@media (min-width: 1280px) {
  .freeBox__fXzGo {
    margin-top: var(--token-JIfrimaWtNy) !important;
  }
}
.button__l9Tou:global(.__wab_instance) {
  object-fit: cover;
  position: relative;
  margin-top: 0;
  width: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
.imageContainer {
  display: flex;
  position: relative;
  flex-direction: column;
  width: 100%;
  height: auto;
  max-width: 100%;
  justify-content: center;
  align-items: center;
  min-width: 0;
  padding: 0px;
}
@media (min-width: 900px) {
  .imageContainer {
    justify-content: center;
    align-items: center;
  }
}
@media (min-width: 600px) {
  .imageContaineroverflowImage {
    justify-content: flex-end;
    align-items: center;
  }
}
@media (min-width: 900px) {
  .imageContaineroverflowImage {
    flex-wrap: nowrap;
    align-items: stretch;
    align-content: stretch;
  }
}
.imageContainerlearnHeading {
  padding-left: var(--token-TCakqYrfy);
  padding-right: var(--token-TCakqYrfy);
}
@media (min-width: 900px) {
  .imageContainerlearnHeading {
    align-items: flex-end;
    justify-content: flex-end;
    padding: 0px;
  }
}
@media (min-width: 1900px) {
  .imageContainerlearnHeading {
    justify-content: center;
    align-items: center;
  }
}
.img__o5ADp {
  position: relative;
  object-fit: contain;
  object-position: center bottom;
  height: 100%;
  align-self: center;
  max-height: 400px;
  width: 100%;
  min-width: 0;
  min-height: 0;
}
.img__o5ADp > picture > img {
  object-fit: contain;
  object-position: center bottom;
}
